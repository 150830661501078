<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:showActionDelete="false"
		tooltipMsg="pmanualofdetail"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
	>
		<template #header>
			{{ $t('detailofthegrou') }}
			<b-link target="_blank" :href="`${model.DomainUrl + model.AgroupUrlNameSk}`">
				{{ model.AgroupNameSk }}
			</b-link>
			<label>&nbsp;{{ $t('id') }}&nbsp;{{ model.AgroupId }}</label>
		</template>
	

	</detail-page>
</template>

<script>
import vue from 'vue';
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import { model, fields } from './attraction-group-form.data';

import apiCommon from '../../services/api/common.api';
import serviceCommon from '../../services/service/common.service';
import serviceCommonApi from '@/services/api/common.api';
import serviceEnums from '../../services/service/enums.service';
import * as links from '../.././router/links';
import loading from '../../mixins/loading.mixin';
import enums from '@/services/helpers/enums';
import { mapFormSchemaFields } from '@/components/general/detail-page/service';

export default {
	components: {
		DetailPage,
	},

	mixins: [loading, detailPageMixin],

	data() {
		return {
			controllerName: 'AttractionGroup',
			routeParam: 'AgroupId',
			model,
			schema: {
				groups: fields,
			},
		};
	},
};
</script>
<style lang="scss">
.vue-form-generator .inline-input-group {
	.form-group.error {
		position: relative;

		.errors {
			position: absolute;
			left: 100%;
			width: fit-content;
		}
	}

	@include ig-first-item(23%, 79%, 22%);
	@include ig-second-item(10%, 10px);
}
</style>