import vueFormGenerator from 'vue-form-generator';
import validators from './attraction-group.validators';
import enums from '@/services/helpers/enums';

export const model = {
	AgroupId: 0,
	AgroupNameSk: null,
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupNameSk',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'namesk2556',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupH1Sk',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'hnamesk3430',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupUrlNameSk',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'urlnamesk',
				},
			},

			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupNameCz',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'namecz2558',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupH1Cz',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'hnamecz3431',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupUrlNameCz',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'urlnamecz',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupNamePl',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'namepl2561',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupH1Pl',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'hnamepl3432',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupUrlNamePl',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'urlnamepl',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupNameEn',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'nameen2564',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupH1En',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'hnameen3433',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupUrlNameEn',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'urlnameen',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupNameDe',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'namede2562',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupH1De',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'hnamede3434',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupUrlNameDe',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'urlnamede',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupNameHu',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'namehu2566',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupH1Hu',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'hnamehu3435',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AgroupUrlNameHu',
				required: true,
				validator: validators.required,
				i18n: {
					label: 'urlnamehu',
				},
			},
		],
	},
];
